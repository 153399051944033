<template>
	<el-table :data="tableData" style="width: 100%">
		<el-table-column label="赛季" width="140">
			<template slot-scope="scope">
				<i class="el-icon-time"></i>
				<span style="margin-left: 10px">{{ scope.row.resultyear }}</span>
			</template>
		</el-table-column>
		<el-table-column label="赛事名称" width="180">
			<template slot-scope="scope">
				<span style="margin-left: 10px">{{ scope.row.gamename }}</span>
			</template>
		</el-table-column>
		<el-table-column label="审核备注" width="300">
			<template slot-scope="scope">
				<span style="margin-left: 10px">{{ scope.row.resultcomment }}</span>
			</template>
		</el-table-column>
		<el-table-column label="状态" width="120">
			<template slot-scope="scope">
				<el-popover trigger="hover" placement="top">
					<p>状态: {{ (Number(scope.row.resultstatus)==2?'已通过':((scope.row.resultstatus)==1?'未通过':'未审核')) }}</p>
					<p>附注: {{ scope.row.resultcomment }}</p>
					<div slot="reference" class="name-wrapper">
						<el-tag :type="(scope.row.resultstatus==2?'success':(scope.row.resultstatus==1?'warning':'primary'))" size="medium">
							{{ (scope.row.resultstatus==2?'已通过':(scope.row.resultstatus==1?'未通过':'未审核'))  }}
						</el-tag>
					</div>
				</el-popover>
			</template>
		</el-table-column>
		<el-table-column label="操作">
			<template slot-scope="scope">
				<el-button size="mini" @click="handleView(scope.$index, scope.row)"
					>详情</el-button
				>
				<el-button size="mini" type="primary" v-if="scope.row.resultstatus==0" @click="handlePass(scope.$index, scope.row)"
					>审核</el-button
				>
				<el-button
					size="mini"
					type="danger"
					v-if="scope.row.resultstatus==0"
					@click="handleReject(scope.$index, scope.row)"
					>驳回</el-button
				>
			</template>
		</el-table-column>
		<el-backtop>
			<i class="el-icon-caret-top"></i>
		</el-backtop>
	</el-table>
</template>

<style>
.el-message-box {
	width: 500px;
}
</style>

<script>
import axios from "axios";
import qs from "qs";
export default {
	name: "resultList",
	data() {
		return {
			userid: -1,
			tableData: [],
		};
	},
	beforeMount() {
		axios({
			url: "/api/hello-user",
			method: "GET",
			headers: {
				Authorization: localStorage.getItem("token"),
			},
		})
			.then((res) => {
				// console.log("userid is");
				this.userid = res.data.userid;
				axios({
					url: "/api/admin/result/get",
					method: "GET",
					headers: {
						Authorization: localStorage.getItem("token"),
					},
				})
					.then((res) => {
						// console.log("gameList is");
						// console.log(res.data);
						this.tableData = res.data;
					})
					.catch((err) => {
						this.messageBox(
							"获取结果列表失败， " + err?.response?.data?.Message ?? "网络断开或神秘错误",
							"error"
						);
						console.log(err.response);
					});
			})
			.catch((err) => {
				this.messageBox(
					"获取用户信息失败， " + err?.response?.data?.Message ?? "网络断开或神秘错误",
					"error"
				);
				console.log(err.response);
			});
	},
	methods: {
		messageBox(msg, type) {
			this.$message[type](msg);
		},
		sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
		handlePass(index, row) {
			// console.log(index, row);
			this.$prompt('该操作会通过'+row.gamename+'的比赛结果，请在下方输入备注：', '提示', {
				type: 'warning',
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				inputPattern: /^[\s\S]*.*[^\s][\s\S]*$/,
				inputErrorMessage: '请输入备注'
			}).then(({ value }) => {
				// console.log(value);
				axios({
					url: "/api/admin/result/review",
					method: "POST",
					headers: {
						"content-type": "application/x-www-form-urlencoded",
					},
					data: qs.stringify({
						id: row.resultid,
						status: 1,
						comment: value,
					}),
				})
				.then(async () => {
					// console.log(res);
					await this.messageBox("通过比赛结果成功", "success");
					await this.sleep(250);
					this.$router.go(0);
				})
				.catch((err) => {
					this.messageBox(
						"通过比赛结果失败， " + err?.response?.data?.Message ?? "网络断开或神秘错误",
						"error"
					);
					console.log("Pass Result Failed");
					console.log(err.response);
				});
			}).catch(() => {});
		},
		handleView(index, row) {
			// console.log(index, row);
			var resultText = "";
			var item = null;
			resultText = "<h4>总冠军</h4>";
			resultText += row.resultinfo.champion;
			resultText += "<br>";
			resultText = resultText + "<h4>甲组成绩</h4>";
			for (var key1 in row.resultinfo.result['1']) {
				// console.log(key1, row.resultinfo.result['1'][key1]);
				item = row.resultinfo.result['1'][key1];
				resultText =  resultText + "第" + item.ranking + "： " + item.deptname + "<br>";
			}
			resultText = resultText + "<h4>乙组成绩</h4>";
			for (var key2 in row.resultinfo.result['2']) {
				// console.log(key2, row.resultinfo.result['2'][key2]);
				item = row.resultinfo.result['2'][key2];
				resultText =  resultText + "第" + item.ranking + "： " + item.deptname + "<br>";
			}
			this.$alert(resultText, row.gamename + '比赛成绩', {
				confirmButtonText: '收起',
				center: true,
				dangerouslyUseHTMLString: true,
			});
		},
		handleReject(index, row) {
			// console.log(index, row);
			this.$prompt('该操作会驳回'+row.gamename+'的比赛结果，请在下方输入驳回理由：', '提示', {
				type: 'warning',
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				inputPattern: /^[\s\S]*.*[^\s][\s\S]*$/,
				inputErrorMessage: '请输入驳回理由'
			}).then(({ value }) => {
				console.log(value);
				axios({
					url: "/api/admin/result/review",
					method: "POST",
					headers: {
						"content-type": "application/x-www-form-urlencoded",
					},
					data: qs.stringify({
						id: row.resultid,
						status: 0,
						comment: value,
					}),
				})
				.then(async () => {
					// console.log(res);
					await this.messageBox("驳回比赛结果成功", "success");
					await this.sleep(250);
					this.$router.go(0);
				})
				.catch((err) => {
					this.messageBox(
						"驳回比赛结果失败， " + err?.response?.data?.Message ?? "网络断开或神秘错误",
						"error"
					);
					console.log("Reject Result Failed");
					console.log(err.response);
				});
			}).catch(() => {});
		},
	},
};
</script>